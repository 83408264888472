import React from "react";
import Head from "next/head";
import Script from "next/script";

export default function MetaTags() {

  return (
    <Head>
      {/* <!-- Google Tag Manager --> */}
      {/* 
         Copy the code below and paste it onto every page of your website.
        Paste this code as high in the <head> of the page as possible:
     */}
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-NDHJG359');
                    `,
        }}
      />

    </Head>
  );

 
}